@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: 1.5s linear infinite spin;
}
/*# sourceMappingURL=index.17b4aeb3.css.map */
